<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">                 
            <template v-for="(item, key) in list_check_box">                 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3> 
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->        

                    <!-- detail_indication_under_review_original  start-->                
                    <div class="content-onglets"  v-if="key == 'detail_indication_under_review_original' && item.show">                             
                        <table class="comparateur2 border-top-gray ">
                            <tbody >
                                <tr>
                                    <th align="left" width="25%" style="text-align: left">{{ $t('bresil.indication_under_review') }}</th>
                                    <td style="text-align: justify;">
                                        <p v-html=" data.bresil['indication_under_review' + suffix] ?  data.bresil['indication_under_review' + suffix]:'-'"></p>
                                    </td>   
                                </tr>
                                <tr>
                                    <th style="text-align: left" >{{ $t('bresil.indication_nhs') }}</th>
                                    <td style="text-align: justify;">
                                        <p v-html=" data.bresil['indication_nhs' + suffix] ? data.bresil['indication_nhs' + suffix]: ''"></p>
                                    </td>   
                                </tr>
                                <tr>
                                    <th style="text-align: left">{{$t('bresil.sane_as_ma')}}</th>
                                    <td style="text-align: justify;">
                                        <p v-html=" data.bresil['sane_as_ma'] ?  $t('bresil.'+ data.bresil['sane_as_ma']) :'-' "></p>
                                    </td>   
                                </tr>
                            </tbody>
                        </table>                 
                    </div> 
                    <!-- detail_indication_under_review_original end -->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
                    
                    <!-- detail_rationale_and_commentary_original TOCHECK-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                    <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief_original' && item.show">
                        <p v-html="data.bresil['evidence_in_brief' +suffix] ? data.bresil['evidence_in_brief'+suffix] : ''"></p>
                    </div>

                    <div class="content-onglets" v-else-if="key == 'detail_background_original' && item.show">
                        <p v-html="data.bresil['background' +suffix] ? data.bresil['background'+suffix] : ''"></p>
                    </div>
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_evidence_original' && item.show">
                        <p v-html="data.bresil['clinical_evidence' +suffix] ? data.bresil['clinical_evidence'+suffix] : ''"></p>
                    </div>

                    <div class="content-onglets" v-else-if="key == 'detail_target_population_original' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tbody>
                                <tr class="text-left">
                                    <td colspan="2">
                                        <p v-html="data.bresil['target_population' +suffix] ? data.bresil['target_population' +suffix] :''"></p>
                                    </td>                        
                                </tr>
                                <tr>
                                    <td width="50%">
                                        {{$t('bresil.Number of patients')}}
                                    </td>
                                    <td width="50%"><p v-html="data.bresil['target_population_number'] ? data.bresil['target_population_number'] :''"></p> </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <!-- Comparator start -->
                    <div class="content-onglets table"  v-else-if="key == 'detail_comparator_original' && item.show">
                        <table class="comparateur2">
                            <thead>
                                <tr>
                                    <th width="33%" >{{ $t('bresil.Comparator name') }}</th>
                                    <th width="33%">{{ $t('bresil.Acceptance') }}</th>
                                    <th width="33%">{{ $t('bresil.Relevance') }}</th>                       
                                </tr>
                            </thead>
                            <tbody>                          
                                <tr v-for="(items,keys) in data.bresil['bresil_comparator_name']" :key="'bresil_comparator_name'+keys">
                                    <td>{{ items['comparator_name'] ? items['comparator_name'] : '-' }}</td>
                                    <td>{{ items['accepted'] ? $t('bresil.accepted_' + items['accepted']) : '-' }}</td>
                                    
                                    <td v-if="keys == 0" :rowspan="data.bresil['bresil_comparator_name'].length" class="text-left">
                                        <p v-html="data.bresil['comparator' + suffix] ? data.bresil['comparator' + suffix] :'-'"></p>
                                    </td>
                                </tr>  
                            </tbody>
                        </table>
                    </div>
                    <!-- Comparator end -->

                    <!--Post marketing study start-->                   
                    <div class="content-onglets" v-else-if="key == 'detail_post_marketing_study_original' && item.show">
                        <p v-html="data.bresil['post_marketing_study' +suffix] ? data.bresil['post_marketing_study'+suffix] : ''"></p>
                    </div>
                    <!--Post marketing study end-->
                    
                    <div class="content-onglets table"  v-else-if="key == 'detail_BresilPrimaryEndpoint' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tbody>                              
                                <tr>
                                    <th :rowspan="data.bresil['bresil_primary_endpoint'].length > 0 ? data.bresil['bresil_primary_endpoint'].length : ''" style=" text-align: left">Primary endpoint</th>
                                  
                                    <template  v-for="(items,keys) in data.bresil['bresil_primary_endpoint']">
                                        <td v-if="keys==0" :key="'bresil_primary_endpoint'+keys">                                        
                                            <span v-html="items['primary_endpoint' + suffix] ? items['primary_endpoint'+suffix] :'-'"></span>                                       
                                        </td>
                                    </template>
                                </tr>                            
                                  <template  v-for="(items,keys) in data.bresil['bresil_primary_endpoint']" >    
                                    <tr v-if="keys != 0" :key="'bresil_primary_endpoint'+keys">    
                                        <td > 
                                            <span v-html="items['primary_endpoint' +suffix]"></span> 
                                        </td>
                                    </tr>  
                                </template>                 
                                <tr>
                                    <th style=" text-align: left"> {{$t('bresil.Acceptance of primary endpoint')}}</th>
                                    <td>{{data.bresil['acceptance_of_primary_endpoint'] ? $t('bresil.' + data.bresil['acceptance_of_primary_endpoint']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Same effect as comparator')}}</th>
                                    <td>{{data.bresil['same_effect_as_comparator'] ? $t('bresil.' + data.bresil['same_effect_as_comparator']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Same response rate as comparator')}}</th>
                                    <td>{{data.bresil['same_response_rate_as_comparator'] ? $t('bresil.' + data.bresil['same_response_rate_as_comparator']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Safety concerns')}}</th>
                                    <td>{{data.bresil['safety_concerns'] ? $t('bresil.' + data.bresil['safety_concerns']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Same safety profile/tolerability as comparator')}}</th>
                                    <td>{{data.bresil['same_safety_profile'] ? $t('bresil.' + data.bresil['same_safety_profile']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t('bresil.QoL data submitted')}}</th>
                                    <td>{{data.bresil['qol_data_submitted'] ? $t('bresil.' + data.bresil['qol_data_submitted']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Acceptance of QoL data')}}</th>
                                    <td>{{data.bresil['acceptance_of_qol_data'] ? $t('bresil.' + data.bresil['acceptance_of_qol_data']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Indirect treatment comparison')}}</th>
                                    <td>{{data.bresil['indirect_treatment_comparison'] ? $t('bresil.' + data.bresil['indirect_treatment_comparison']) : '-'}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('bresil.Acceptance of indirect treatment comparison')}}</th>
                                    <td>{{data.bresil['acceptance_of_indirect_treatment_comparison'] ? $t('bresil.' + data.bresil['acceptance_of_indirect_treatment_comparison']) : '-'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="content-onglets"  v-else-if="key == 'detail_BresilEconomicEvaluation' && item.show">                    
                        <template v-if="data.bresil['cost_effectiveness_analysis' +suffix]">
                            <p v-html="data.bresil['cost_effectiveness_analysis' +suffix]"></p>
                        </template>
                        
                        <table class="comparateur2" v-for="(items,keys) in data.bresil['bresil_economic_evaluation']" :key="'bresil_economic_evaluation'+keys">
                            <tbody class="noLineLR">                         
                                <tr v-if="items['cost_effectiveness_analysis' +suffix]">
                                    <td colspan="2">
                                        <p v-html="items['cost_effectiveness_analysis' +suffix] ? items['cost_effectiveness_analysis' +suffix] :'-'"></p>
                                    </td>
                                </tr>
                            
                                <tr>
                                    <td width="50%">{{ $t('bresil.Considered cost-effective')}}</td>    
                                    <td> {{items['considered_cost_effective'] ? $t('bresil.'+ items['considered_cost_effective']) :'-'}}</td>   
                                </tr>
                                <tr>
                                    <td width="50%"> {{$t('bresil.Additional value related to administration')}}</td> 
                                    <td> {{items['additional_value_related_to_administration']  ?  $t('bresil.'+ items['additional_value_related_to_administration']) : '-'}}</td> 
                                </tr>
                            </tbody>
                        </table>
                    
                    </div>
                                
                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.bresil.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!-- @todo detail_BresilPrice -->
                   
                     <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!-- Bresil Budget impact start--->    
                    <div class="content-onglets table"  v-else-if="key == 'detail_budget_impact' && item.show">
                        <p v-html="data.bresil['add_bi']?  data.bresil['add_bi'] :'-'"></p>
                        <h3> {{$t('bresil.Budget impact Analysis')}}</h3>
                        <p v-html="data.bresil['budget_impact_analysis' +suffix] ? data.bresil['budget_impact_analysis' +suffix] : '-'"></p>
                        
                        <table class="comparateur2 text-left border-top-gray" >
                            <tbody>
                                <tr>
                                    <th width="40%" style="text-align: left">Total cost per patient per year (R$) </th>
                                    <td>{{data.bresil['total_cost_p_patient_p_year_r']? data.bresil['total_cost_p_patient_p_year_r'] : '-'}}</td>
                                </tr>
                                <tr>
                                    <th style="text-align: left">Number of eligible patients Year 1</th>
                                    <td>{{data.bresil['num_of_eligible_patients']? data.bresil['num_of_eligible_patients'] : '-'}}</td>
                                </tr>
                                <tr>
                                    <th style="text-align: left">Number of eligible patients Year 5</th>
                                    <td>{{data.bresil['num_of_eligible_patients_5']?  data.bresil['num_of_eligible_patients_5'] : '-'}}</td>
                                </tr>
                                <tr>
                                    <th style="text-align: left">Net medicines budget impact (R$) Year 1</th>
                                    <td>{{data.bresil['net_bi_year_1']? data.bresil['net_bi_year_1'] : '-'}}</td>
                                </tr>
                                <tr>
                                    <th style="text-align: left">Net medicines budget impact (R$) Year 5</th>
                                    <td>{{data.bresil['net_bi_year_5']? data.bresil['net_bi_year_5'] : '-'}}</td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <!-- Bresil Budget impact end--->    

                    <!--Aditional comments on methodology start-->                              
                    <div class="content-onglets" v-else-if="key == 'detail_additionl_comments_original' && item.show">
                        <p v-html="data.bresil['additionl_comments' +suffix] ? data.bresil['additionl_comments'+suffix] : ''"></p>
                    </div>
                    <!--Aditional comments on methodology end-->

                    <!--final_consideration start-->
                    <div class="content-onglets" v-else-if="key == 'detail_final_consideration_original' && item.show">
                        <p v-html="data.bresil['final_consideration' +suffix] ? data.bresil['final_consideration'+suffix] : ''"></p>
                    </div>
                    <!--final_consideration end-->

                    <!--CONITEC recommendation start-->                        
                    <div class="content-onglets" v-else-if="key == 'detail_recommendation_conitec_original' && item.show">
                        <p v-html="data.bresil['recommendation_conitec' +suffix] ? data.bresil['recommendation_conitec'+suffix] : ''"></p>
                    </div>
                    <!--CONITEC recommendation end-->

                    <!--Public consultation start-->                            
                    <div class="content-onglets" v-else-if="key == 'detail_public_consultation_original' && item.show">
                        <p v-html="data.bresil['public_consultation' +suffix] ? data.bresil['public_consultation'+suffix] : ''"></p>
                    </div>
                    <!--Public consultation end-->

                    <!--Final resolution start-->                   
                    <div class="content-onglets" v-else-if="key == 'detail_final_resolution_original' && item.show">
                        <p v-html="data.bresil['final_resolution' +suffix] ? data.bresil['final_resolution'+suffix] : ''"></p>
                    </div>
                    <!--Final resolution end-->
                    
                    <!--Decision start-->
                    <div class="content-onglets" v-else-if="key == 'detail_decision_original' && item.show">
                        <p v-html="data.bresil['decision' +suffix] ? data.bresil['decision'+suffix] : ''"></p>
                    </div>
                    <!--Decision end-->
                    

                    <!-- detail_key_documents -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                </div>
            </template>
           
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>


<script>
import LeftSection from '../LeftSection.vue'
import Conclusion from '../conclusion.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import TreatmentLine from '../treatment_line.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'bresil',
    components : {
        LeftSection, 
        Conclusion,        
        EvaluationEconomic,
        EconomicEvaluation,
        TreatmentLine,
        LinkAgency,
        EssaisClinique,
        KeyDocument
    },
    data(){
        return {
            list_check_box : {
                 // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_indication_under_review_original : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false}, 
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_evidence_in_brief_original : {'title' : 'bresil.Evidence in brief', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_background_original : {'title' : 'bresil.Background', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_clinical_evidence_original : {'title' : 'bresil.Clinical Evidence', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_target_population_original : {'title' : 'bresil.Target population', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_comparator_original : {'title' : 'bresil.Comparator', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_post_marketing_study_original : {'title' : 'bresil.Post marketing study', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_BresilPrimaryEndpoint : {'title' : 'bresil.Clinical effectiveness evidence', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_BresilEconomicEvaluation : {'title' : 'bresil.Health economic evidence', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true}, 
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true}, 
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true}, 
                detail_BresilPrice : {'title' : 'bresil.Cost', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_budget_impact : {'title' : 'bresil.Budget impact', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_additionl_comments_original : {'title' : 'bresil.Additional comment on methodology', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_final_consideration_original : {'title' : 'bresil.FINAL CONSIDERATIONS', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_recommendation_conitec_original : {'title' : 'bresil.CONITEC recommendation', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_public_consultation_original : {'title' : 'bresil.Public consultation', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_final_resolution_original : {'title' : 'bresil.Final resolution', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_decision_original : {'title' : 'bresil.Decision', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false}, 
                
            }, 
            suffix : '',
        }
     },
    created(){
        this.suffix = ''         
        if (this.$i18n.locale != 'pt'){ 
            this.suffix = '_original' 
        }
        
        // condition to show check box in left section [enable]
        if(this.data && this.data.bresil){
            if (this.data.bresil['indication_under_review'+this.suffix] || this.data.bresil['indication_nhs'+this.suffix]) {
                this.list_check_box['detail_indication_under_review_original'].enable = true
            }              
            
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale))  {  //
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.bresil['evidence_in_brief'+this.suffix])  { 
                this.list_check_box['detail_evidence_in_brief_original'].enable = true
            }
            if(this.data.bresil['background'+this.suffix])  { 
                this.list_check_box['detail_background_original'].enable = true
            }
            if(this.data.bresil['clinical_evidence'+this.suffix])  { 
                this.list_check_box['detail_clinical_evidence_original'].enable = true
            }
            if(this.data.bresil['target_population'+this.suffix] || this.data.bresil['target_population_number'])  { 
                this.list_check_box['detail_target_population_original'].enable = true
            }

            if(this.data.bresil['comparator'+this.suffix] || this.data.bresil['bresil_comparator_name'].length > 0)  { 
                this.list_check_box['detail_comparator_original'].enable = true
            }

            if(this.data.bresil['post_marketing_study'+this.suffix])  { 
                this.list_check_box['detail_post_marketing_study_original'].enable = true
            }
            if(this.data.bresil['bresil_primary_endpoint'].length > 0 ||
            this.data.bresil['acceptance_of_primary_endpoint'] ||
            this.data.bresil['same_effect_as_comparator'] ||
            this.data.bresil['same_response_rate_as_comparator'] ||
            this.data.bresil['safety_concerns'] ||
            this.data.bresil['same_safety_profile'] ||
            this.data.bresil['qol_data_submitted'] ||
            this.data.bresil['acceptance_of_qol_data'] ||
            this.data.bresil['indirect_treatment_comparison'] ||
            this.data.bresil['acceptance_of_indirect_treatment_comparison'] 
            )  { 
                this.list_check_box['detail_BresilPrimaryEndpoint'].enable = true
            }

            if( this.data.bresil['cost_effectiveness_analysis'+ this.suffix] || this.data.bresil['bresil_economic_evaluation'].length > 0 )  { 
                this.list_check_box['detail_BresilEconomicEvaluation'].enable = true
            } 
            
            if(this.data.bresil.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }    
            
            //@todo 
            // if (this.data.bresil['bresil_price'].length > 0 || this.data.bresil['bresil_cost_treatment'].length > 0){
            //     this.list_check_box['detail_BresilPrice'].enable = false   
            // }
            
            //@todo  
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }

            if( this.data.bresil['add_bi'] ||
                this.data.bresil['total_cost_p_patient_p_year_r'] ||
                this.data.bresil['num_of_eligible_patients'] ||
                this.data.bresil['num_of_eligible_patients_5'] ||
                this.data.bresil['net_bi_year_1'] ||
                this.data.bresil['net_bi_year_5'] ||
                this.data.bresil['budget_impact_analysis'+this.suffix] 
            )  { 
                this.list_check_box['detail_budget_impact'].enable = true
            }
            
            if(this.data.bresil['additionl_comments'+this.suffix]) { 
                this.list_check_box['detail_additionl_comments_original'].enable = true
            }            
            if(this.data.bresil['final_consideration'+this.suffix]) { 
                this.list_check_box['detail_final_consideration_original'].enable = true
            }
            if(this.data.bresil['recommendation_conitec'+this.suffix]) { 
                this.list_check_box['detail_recommendation_conitec_original'].enable = true
            } 
            if(this.data.bresil['public_consultation'+this.suffix]) { 
                this.list_check_box['detail_public_consultation_original'].enable = true
            } 
            if(this.data.bresil['final_resolution'+this.suffix]) { 
                this.list_check_box['detail_final_resolution_original'].enable = true
            }
            if(this.data.bresil['decision'+this.suffix]) { 
                this.list_check_box['detail_decision_original'].enable = true
            }
            
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

        }     
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },        
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}  

</script>

<style>

</style>